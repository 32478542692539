/// <reference path='./index.d.ts' />

import { ActionHandlers, ActionSignature, Store } from '../../../lib/pork/index';

// TODO maybe move this to index.d.ts
type State = {
    [key: string]: string; // keyed on interviewID, val is contents of the note
};

// TODO move these to index.d.ts
interface INotesData {
    interviewID: string,
    data: string
};

export class NoteDataStore extends Store<State> {

    static namespace = 'note';

    initialState: State = {};

    deserialize(state: State): State { return state; }

    actionHandlers: ActionHandlers = { loadNoteData, edit }
}

function loadNoteData(state: State, action: ActionSignature<INotesData>) {
    const interviewID = action.payload.interviewID;
    const noteText    = action.payload.data;

    // When loading note data, ignore the rest
    return { [interviewID]: noteText };
}

function edit(state: State, action: ActionSignature<INotesData>) {
    const interviewID = action.payload.interviewID;
    const noteText    = action.payload.data;

    return Object.assign({}, state, { [interviewID]: noteText });
}
