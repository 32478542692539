import { atom } from 'jotai';
import axios from 'axios';
import { protectedApiCall } from './network';
import { loadOrg, loadTeam } from './base';

export interface DiscoverySession {
    sessionID: string;
    apiToken: string;
    legacyApiToken: string;
    user: DiscoverySessionUser | undefined;
    session: DiscoverySessionContext | any;
    state: DiscoverySessionState;
}

export interface DiscoverySessionContext {
    groupID: string;
    orgID: string;
}

export interface DiscoverySessionUser {
    userID: string;
    username: string;
    email: string;
}

export enum DiscoverySessionState { loading, loading_org, loading_group, ready }

export const discoverySession: DiscoverySession = {
    sessionID: "",
    apiToken: "",
    legacyApiToken: "",
    user: undefined,
    session: {},
    state: DiscoverySessionState.loading
}

export const discoverySessionAtom = atom(discoverySession);

// loadOrg(setBase, setCategoryState, org, keycloak.token)
export const setSessionByOrgID = (set, session, orgID: string, setBase, setCategoryState) => {
    protectedApiCall(session, "/session/v1", null, {
        params: {
            orgID
        }
    }, "put").then((r) => {
        set((session) => {
            let buffer = {
                ...session,
                state: DiscoverySessionState.loading_org
            }

            try {
                buffer.session.orgID = orgID;
            } catch (e) {}

            try {
                //@ts-ignore
                buffer.session.groupID = r.session.groupID;
            } catch(e) {}

            // if we can load the new org with it then we're good
            loadOrg(setBase, setCategoryState, orgID, buffer, () => {
                console.log('persisting context from org load');

                buffer.state = DiscoverySessionState.ready;

                persistSessionChange(buffer);
            })

            return buffer;
        });
    });
}

export const setSessionByGroupID = (setBase, setCategoryState, set, existing, groupID: string) => {
    console.log('setSessionByGroupID ' + groupID);

    set((session) => {
        let buffer = {
            ...session,
            state: DiscoverySessionState.loading_org
        }

        try {
            buffer.session.groupID = groupID;
        } catch (e) {}

        return buffer;
    });

    protectedApiCall(existing, "/session/v1", null, {
        params: {
            groupID
        }
    }, "put").then((r: any) => {
        set((session) => {
            let buffer = {
                ...session,
                ...r.data,
                session: {
                    groupID: groupID
                },
                state: DiscoverySessionState.loading_group
            }

            try {
                buffer.session.groupID = groupID;
            } catch (e) {}
    
            persistSessionChange(buffer);

            loadTeam(setBase, setCategoryState, groupID, buffer.apiToken, existing, () => {
                set((session) => {
                    let buffer = {
                        ...session,
                        state: DiscoverySessionState.ready
                    }
            
                    try {
                        buffer.session.groupID = groupID;
                    } catch (e) {}
            
                    persistSessionChange(buffer);

                    return buffer;
                });
            });

            return buffer;
        });
    });
}

export async function persistSessionChange(base) {
    try {
        const {
            sessionID
        } = base;

        localStorage.setItem(`sessionPack-${sessionID}`, JSON.stringify(base));
    }
    catch (e) {
        console.error(e);
    }
}
