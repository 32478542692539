/// <reference path="./index.d.ts" />

import { ActionSignature, ActionHandlers, Store } from '../../../lib/pork';
import { assign, clone, cloneDeep, each, filter } from 'lodash';
import moment from 'moment';

type State = AppState;

// TODO whitelist these entries instead of blacklisting them in the cache scheme.
export class AppStore extends Store<State> {
    static namespace = 'appState';

    initialState: State = {
        pathRequest: '',
        app: {
            chatOpen: false,
            notificationSettingsOpen: false,
            notificationOpen: false,
            orgTreeOpen: true,
            selectedData: { period: 1 },
            invalidToken: false,
            validLicense: true,
            readyToLogout: false,
            validLicenseExpireDate: 'none',
            isFetchingLicense: false,
            currentArtifactTab: 0,
            isBeaconLoaded: false,
            presentationWeek: 0,
            presentationGroup: undefined,
            presentationRefreshFlag: false,
            isFetching: false,
            unreadNotifications: 0,
            userEditLastMessageFlag: 0,
            toastQueue: []
        },
        support: {
            suggestions: [{path: "/canvas", role: "*", article: ""}]
        },
        canvas: { 
            orientation: 'flex', 
            showLinkedFindingBadges: true,
            showLinkedHypothesisBadges: false,
            showAllComments: false,
            showLinksByColor: false,
            nubMap: {} 
        },
        discovery: {
            interviewList: true,
            transcriptAutoplay: true,
            isFetchingExports: false,
            isFetchingNotes: false,
            isFetchingTranscript: false,
            showConflictPrompt: false,
            conflictPromptUsernameCache: '',
            isSavingArtifactText: false
        }
    }

    deserialize(state: State) {
        let newState: State = cloneDeep(state);
        newState.app.invalidToken = false;
        newState.discovery.isFetchingExports = false;
        return newState;
    }

    serialize(state: State) {
        return state;
    }

    actionHandlers: ActionHandlers = {
        clearPrivateData,

        invalidToken,
        setLicenseValidity,
        toggleChat,
        setBeaconAsLoaded,

        removeToastFromQueue,
        addToToastQueue,
        flagForLogout,

        //org tree / chart columns / period - affects app state
        toggleOrgTree, setSelected,
        openOrgTree, closeOrgTree,
        setCurrentTab,
        toggleNotification,
        setNotificationOff,

        //canvas
        orientCanvas: orientCanvas,
        toggleCanvasLinkBadgeView,
        toggleCanvasAllCommentsView,
        toggleCanvasLinkedByColor,
        setUnreadNotificationCount,
        toggleCanvasFindingLinkBadgeView,

        selectPresentationWeek,
        selectPresentationGroup,
        setPresentationRefreshFlag,
        setLicenseFetching,
        setNotificationSettingsIsOpen,
        clearLogoutFlag,

        setIsSavingArtifactText,

        setFlagUserEditLastMessage,

        setIsFetching,
        setIsFetchingNotes,
        setShowConflictPrompt,
        setIsFetchingTranscript,
        setNubMap,
        setPathRequest,
        // Discovery
        toggleInterviewList, selectInterview,

        // export progress notification (discovery)
        exportInterviewsStart, exportInterviewsEnd
    }
}

function clearLogoutFlag(state: State, action: ActionSignature<any>): State {
    return changeNested(state, 'app', {readyToLogout: false})
}

function flagForLogout(state: State, action: ActionSignature<any>): State {
    return changeNested(state, 'app', {readyToLogout: true})
}

function clearPrivateData (state: State, action: ActionSignature<ClearPrivateDataPayload>) {
    return assign({}, this.initialState, {app: state.app});
}

function changeNested(state: State, name: string, data: Object): State {
    return <State>assign({}, state, {
        [name]: assign({}, state[name] ? state[name]: undefined, data)
    });
}

//Canvas
function orientCanvas(state: State, action: ActionSignature<string>) {
    let currentOrientation = state.canvas.orientation;

    if (currentOrientation === "list") {
        currentOrientation = "normal";
    }
    else {
        currentOrientation = "list";
    }

    return changeNested(state, 'canvas', {orientation: currentOrientation});
}

function setNubMap(state: State, action: ActionSignature<any>) {
    const canvasData = action.payload.canvasData;
    let nubMap = {};
    let sortedCustomerSegments = [];

    each (canvasData.categories, category => {
        const isSegmentCustomerSegments = category.name === "Customer Segments";

        if (isSegmentCustomerSegments) {
            sortedCustomerSegments = category.entries.sort((a,b) => {
                if (a === undefined || b === undefined) return 0;

                //@ts-ignore
                const aCreated = moment(a.created);
                //@ts-ignore
                const bCreated = moment(b.created);
    
                if (aCreated.isBefore(bCreated)) {
                    return 1;
                }
                else {
                    return -1;
                }
            });
        }
    });

    let currentOrder = 0;
    each(sortedCustomerSegments, customerSegment => {
        nubMap[customerSegment.id] = currentOrder;
        currentOrder += 1;
    });

    return changeNested(state, 'canvas', {nubMap});
}

function toggleCanvasFindingLinkBadgeView(state: State, action: ActionSignature<any>): State {
    const newToggle = !state.canvas.showLinkedFindingBadges;

    return changeNested(state, 'canvas', {showLinkedFindingBadges: newToggle})
}

function toggleCanvasAllCommentsView(state: State, action: ActionSignature<any>): State {
    const newToggle = !state.canvas.showAllComments;

    return changeNested(state, 'canvas', {showAllComments: newToggle})
}

function toggleCanvasLinkedByColor(state: State, action: ActionSignature<any>): State {
    const newToggle = !state.canvas.showLinksByColor;

    return changeNested(state, 'canvas', {showLinksByColor: newToggle})
}

function setUnreadNotificationCount(state: State, action: ActionSignature<any>): State {
    const {
        count
    } = action.payload;

    return changeNested(state, 'app', {unreadNotifications: count})
}

function toggleCanvasLinkBadgeView(state: State, action: ActionSignature<any>): State {
    const newToggle = !state.canvas.showLinkedHypothesisBadges;

    return changeNested(state, 'canvas', {showLinkedHypothesisBadges: newToggle})
}

function toggleChat(state: State, action: ActionSignature<void>): State {
    let newState = {
        chatOpen: !state.app.chatOpen
    };

    return changeNested(state, 'app', newState);
}

function setNotificationOff(state: State, action: ActionSignature<void>): State {
    const newState = {
        notificationOpen: false
    };

    return changeNested(state, 'app', newState);
}

function setNotificationSettingsIsOpen(state: State, action: ActionSignature<boolean>): State {
    return changeNested(state, 'app', { notificationSettingsOpen: action.payload });
}

function toggleNotification(state: State, action: ActionSignature<void>): State {
    const newState = {
        notificationOpen: !state.app.notificationOpen
    };

    return changeNested(state, 'app', newState);
}

function setFlagUserEditLastMessage(state: State, action: ActionSignature<boolean>): State {
    return changeNested(state, 'app', { userEditLastMessageFlag: action.payload });
}

function setIsSavingArtifactText(state: State, action: ActionSignature<boolean>): State {
    return changeNested(state, 'discovery', { isSavingArtifactText: action.payload });
}

function setShowConflictPrompt(state: State, action: ActionSignature<boolean>): State {
    const payload = action.payload;

    return changeNested(state, 'discovery', { 
        showConflictPrompt: payload[0],
        conflictPromptUsernameCache: payload[1]
    });
}

function setPathRequest(state: State, action: ActionSignature<boolean>): State {
    return changeNested(state, 'pathRequest', action.payload);
}

function setIsFetchingNotes(state: State, action: ActionSignature<boolean>): State {
    return changeNested(state, 'discovery', { isFetchingNotes: action.payload });
}

function setIsFetchingTranscript(state: State, action: ActionSignature<boolean>): State {
    return changeNested(state, 'discovery', { isFetchingTranscript: action.payload });
}

function setIsFetching(state: State, action: ActionSignature<boolean>): State {
    return changeNested(state, 'app', { isFetching: action.payload });
}

function setCurrentTab(state: State, action: ActionSignature<void>): State {
    if (action.error) return state;

    let {target} = action.payload;

    return changeNested(state, 'app', { currentArtifactTab: target });
}

//Discovery
function toggleInterviewList(state: State, action: ActionSignature<void>): State {
    if (action.error) return state;

    let interviewList = state.discovery ? !state.discovery.interviewList : false;

    return changeNested(state, 'discovery', { interviewList });
}

function selectInterview(state: State, action: ActionSignature<string>): State {
    if (action.error) return state;

    return changeNested(state, 'discovery', { interviewID: action.payload });
}

function openOrgTree(state: State, action: ActionSignature<void>) {
    if (action.error) return state;

    let orgTreeOpen = true;

    return changeNested(state, 'app', { orgTreeOpen });
}

function setPresentationRefreshFlag(state: State, action: ActionSignature<any>) {
    const {
        value
    } = action.payload;

    return changeNested(state, 'app', {presentationRefreshFlag : value});
}

function selectPresentationWeek(state: State, action: ActionSignature<any>) {
    const {
        week
    } = action.payload;

    return changeNested(state, 'app', {presentationWeek: week, presentationRefreshFlag: false});
}

function selectPresentationGroup(state: State, action: ActionSignature<any>) {
    const {
        groupID
    } = action.payload;

    return changeNested(state, 'app', {presentationGroup: groupID, presentationRefreshFlag: false});
}

function closeOrgTree(state: State, action: ActionSignature<void>) {
    if (action.error) return state;

    let orgTreeOpen = false;

    return changeNested(state, 'app', { orgTreeOpen });
}

function toggleOrgTree(state: State, action: ActionSignature<void>) {
    if (action.error) return state;

    let {isAdminView} = action.payload;

    let orgTreeOpen = !isAdminView ? state.app ? !(state.app.orgTreeOpen) : false : true;

    return changeNested(state, 'app', { orgTreeOpen });
}

function setBeaconAsLoaded(state: State, action: ActionSignature<[AppSuggestions]>) {

    // Clone the current state
    const newState = cloneDeep(state);
    // Update it's beacon data
    newState.app.isBeaconLoaded = true;

    // Assign the incoming payload to the suggestion blob
    newState.support.suggestions = action.payload;

    // Return the updated state
    
    return newState;
}

//Org Tree / Column Selection / Period selection
function setSelectedData(state: State, key: string, data: number): State {
    let selectedData = <SelectedData>assign({}, clone(state.app.selectedData), { [key]: data });

    return changeNested(state, 'app', { selectedData });
}

function setSelected(state: State, action: ActionSignature<SelectedAction>): State {
    if (action.error) return state;

    return setSelectedData(state, action.payload.key, action.payload.data);
}

// Remove a single entry from the queue
function removeToastFromQueue(state: State, action: ActionSignature<any>): State {
    const oldToast = clone(action.payload);

    const currentQueue = clone(state.app.toastQueue);

    const newQueue = filter(currentQueue, entry => {
        return !(entry.id === oldToast.id);
    });

    if (currentQueue.length === newQueue.length) {
        console.error(`no matching toast was found in the queue ${oldToast}`);
    }

    return changeNested(state, 'app', { toastQueue: newQueue });
}

// Simply drop the toast, into the queue!
function addToToastQueue(state: State, action: ActionSignature<any>): State {
    const newToast = clone(action.payload);

    let currentQueue = clone(state.app.toastQueue);

    // IWT-1669, 1/29/2021 an old client "refreshing" may not have
    // an empty array for this in cache
    if (!currentQueue) {
        currentQueue = [];
    }

    currentQueue.push(newToast);

    return changeNested(state, 'app', { toastQueue: currentQueue });
}

// On receiving a 403 "Illegitimate access token"
function invalidToken(state: State, action): State {
    return changeNested(state, 'app', { invalidToken: true });
}

// When something detects a change in the users license validity
function setLicenseValidity(state:State, action: ActionSignature<LicenseValidity>): State {
    return changeNested(state, 'app', { validLicense: true, validLicenseExpireDate: action.payload.validLicenseExpireDate, isFetchingLicense: false });
}

function setLicenseFetching(state:State, action: ActionSignature<any>): State {
    return changeNested(state, 'app', { isFetchingLicense: action.payload.isFetching });
}

// set state for interview list export loading indicator of some sort
function exportInterviewsStart(state: State, action: ActionSignature<null>): State {
    if (action.error) return state;

    const newState = cloneDeep(state);
    newState.discovery.isFetchingExports = true;
    return newState;
}

// set state for interview list export loading indicator of some sort
function exportInterviewsEnd(state: State, action: ActionSignature<null>): State {
    if (action.error) return state;

    const newState = cloneDeep(state);
    newState.discovery.isFetchingExports = false;
    return newState;
}

export default AppStore;
