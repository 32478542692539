/// <reference path='./index.d.ts' />

import {Request} from 'superagent';

import { CallbackHandler, ApiBase } from '../base';
import {DiscoveryApi} from '../discovery-api';

class UserApi extends DiscoveryApi {
    routePrefix = 'user';

    userDetails(callback: CallbackHandler<api.UserResponse>, query?: {accessToken: string}) {
        this.request('get', '', query, null, callback);
    }

    updateUserDetails(details: api.UserPut, callback: CallbackHandler<any>) {
        this.putUpload('', details, null, callback);
    }

    signin(username: string, password: string, callback: CallbackHandler<api.UserResponse>) {
        this.upload('/signin',{
            username,
            password
        }, null, callback);
    }

    forgotPassword(email: string, callback: CallbackHandler<api.GenericResponse>) {
        return this.put('/password', {
            email
        }, callback);
    }

    // send authcode-augmented signin req for admins who require 2fa
    signinWithAuthCode(username: string, password: string, authCode: string, callback: CallbackHandler<api.UserResponse>) { // TODO genresp
        return this.upload('/signin', { username, password, authCode }, null, callback);
    }

    licenseTimeline(request: api.LicenseTimeline, callback: CallbackHandler<api.LicenseTimelineResponse>) {
        return this.get("/license", request, callback);
    }

    putProductRequirement(request: api.ProductRequirement, callback: CallbackHandler<api.GenericResponse>) {
        return this.put("/productrequirement", request, callback);
    }

    licenseTimelineSoft(request: api.LicenseTimelineSoft, callback: CallbackHandler<api.GenericResponse>) {
        return this.get("/license/soft", request, callback);
    }

    adminReport(request: api.LicenseTimelineSoft, callback: CallbackHandler<api.GenericResponse>) {
        return this.get("/admin/report", request, callback);
    }

    getAllTokens(request: api.LicenseTimelineSoft, callback: CallbackHandler<api.GenericResponse>) {
        return this.get("/license/tokens", request, callback);
    }

    releaseToken(request: api.ProductTokenEmpty, callback: CallbackHandler<api.GenericResponse>) {
        return this.put("/token/empty", request, callback);
    }

    extendToken(request: api.ProductTokenEmpty, callback: CallbackHandler<api.GenericResponse>) {
        return this.put("/token/extend", request, callback);
    }

    reserveToken(request: api.ProductTokenReserve, callback: CallbackHandler<api.GenericResponse>) {
        return this.put("/token/reserve", request, callback);
    }

    deleteLicenseToken(request: api.DeleteLicenseToken, callback: CallbackHandler<api.GenericResponse>) {
        return this.delete("/license/token", request, callback);
    }

    extendLicense(request: api.LicenseExtension, callback: CallbackHandler<api.LicenseTimelineResponse>) {
        return this.put("/license/extend", request, callback);
    }

    signinNonce(nonce: string, callback: CallbackHandler<api.UserResponse>) {
        return this.get('/password', {nonce}, callback);
    }

    search(searchData: api.UserSearchRequest, callback: CallbackHandler<api.UserSearchResponse>): Request {
        return this.get('/search', searchData, callback);
    }

    setContext(orgID: string, groupID: string = null, callback: CallbackHandler<api.UserContext>) {
        this.put('/context', {orgID, groupID}, callback);
    }
}

export let userAPI = new UserApi();
