/// <reference path='../typings/superagent/superagent.d.ts' />

import { isEmpty, isObject, isArray, has, each, any } from 'lodash';
import { default as superagent, Request, Response, CallbackHandler } from 'superagent';
import { DiscoveryApi } from 'app/api/discovery-api';
import { history } from '..';
import token from 'basic-auth-token';
import * as uuid from '../util/uuid';
import { dispatch } from '../../lib/pork';
import { constructAction } from '../stores/util';

import axios from 'axios';
import { appLog } from '../util';
//import { DiscoveryApi } from './discovery-api';

// Example environment variables
// export LINX_API_CI=true
// export LINX_API_KEY=X

// Include this file and fall the following function to interact with
// and query the SDK

// NOTE: If you don't have a cookie in your browser (E.G., running this code from an actual *.innovationwithin.services)
// you will need to set the env variable LINX_API_KEY=X <- to whatever would go in the cookie value
// and the SDK should handle the rest

// contact: Justin Barnyak <justin@innovationwithin.com> for any questions/concerns/info to vinx or this SDK.

// source should be a string such as "settings", "canvas", "blobs"
// config is a JSON object containing the entire API config for your source, outlined in the docs
// context is a JSON object containing the required context for your call.  Example ({groupID: "lasjdlkaj"})
async function paw(source, context, config) {
    if (source === undefined || config === undefined) {
        console.error("Called paw with missing required params");
        return;
    }
    // Apply any middle ware we might need to for the requests
    const middleWareResults = _sourceMiddleware(source, context, config);

    context = middleWareResults[0];
    config = middleWareResults[1];

    // Process the request and wait for the response
    const processResults = await _waitForProcessToComplete(source, {context, config});

    if (processResults[0] === true) {
        return processResults[1];
    }
    else {
        console.error("Paw error: problem processing enqueued request");
        console.error(processResults[1]);

        return;
    }
}

export async function _waitForProcessToComplete(source, payload) {
    // Find the correct route into vinx from here
    const root = _getRootVinxURL() + source;

    const axiosResult = await axios.post(root + "?ci=" + (_getIsCI() ? "true" : "false"), {
        ...payload
      }, {
        withCredentials: false
      });

    return [true, axiosResult];
}

function _sourceMiddleware(source, context, config) {
    return [context, config];
}

function _getClientAPIKey() {
    return process.env.LINX_API_KEY;
}

function _getIsCI() {
    // lock CI in for now
    // TODO: remove this when we go live
    return true;

    if (((typeof window !== "undefined") ? window : {}).location.href.indexOf("ci.innovationwithin.services") > 0) {
        return true;
    }

    if (((typeof window !== "undefined") ? window : {}).location.href.indexOf("justin.innovationwithin.services") > 0) {
        return true;
    }

    if (((typeof window !== "undefined") ? window : {}).location.href.indexOf("cole.innovationwithin.services") > 0) {
        return true;
    }

    if (((typeof window !== "undefined") ? window : {}).location.href.indexOf("dev.innovationwithin.services") > 0) {
        return true;
    }

    return false;
}

export function _getRootVinxURL() {
    return `${process.env.NEXT_PUBLIC_LINX_URL}/`;
}


// We monitor globally for invalidated access tokens
const dispatchBadToken = () => {
    const action = constructAction('appState.invalidToken', {})
    dispatch(action);
}

//@ts-ignore
JSON.safeStringify = (obj, indent = 2) => {
    let cache = [];
    const retVal = JSON.stringify(
        obj,
        (key, value) =>
            typeof value === "object" && value !== null
                ? cache.includes(value)
                    ? undefined // Duplicate reference found, discard key
                    : cache.push(value) && value // Store value in our collection
                : value,
        indent
    );
    cache = null;
    return retVal;
};

export type CallbackHandler<T> = CallbackHandler<T>;

export interface ApiSettings {
    apiKey?: string;
    apiSecret?: string;
    apiEndpoint: string;
    beaconConfig: string;
}

export class ApiBase {
    settings: ApiSettings;
    useBasicAuth: boolean;

    protected routePrefix: string = '';

    getEndpoint(route?: string) {
        if (route.startsWith('http')) return route;

        let { apiEndpoint } = this.settings;
        return `${apiEndpoint}/${this.routePrefix}${route}`;
    }

    authToken() {
        let { apiKey, apiSecret } = this.settings;

        return token(apiKey, apiSecret);
    }

    protected setupRequest(request: Request) {
        let { apiKey, apiSecret, apiEndpoint } = this.settings;

        if (this.useBasicAuth) {
            let authQueryIndex = request._query.indexOf('auth=true');

            if (authQueryIndex > -1) {
                request._query[authQueryIndex] = 'auth=' + this.authToken();
            } else {
                request
                    .auth(apiKey, apiSecret);
            }

        }
    }

    protected forbiddenCallback(error: ResponseError<T>, response: Response<T>) { }
    protected errorCallback(error: ResponseError<T>, response: Response<T>) { }

    protected finishRequest<T>(request: Request, callback?: CallbackHandler<T>) {
        request.end((err, response) => {
            let error: Object = null,
                errors = {};

            if (err || (response && response.statusCode !== 200)) {
                error = (isObject(err) && response && response.body) ? response.body : err;

                if (error) {
                    if (response && response.body && isObject(response.body) && !isArray(response.body)) {
                        for (let errorType in response.body) {
                            let errs = response.body[errorType];
                            errs.map(errOb => {
                                errors[errOb.parameter] = errOb.error;
                            });
                        }

                        error = errors;
                    } else {
                        error = { api: response ? response.body || response.text : error };
                    }
                }
            }

            // If their token has expired, we freeze the app and show them a dialog prompting them to
            // sign back in.
            if (response && response.statusCode === 403 && response.text === 'Illegitimate access code') {
                return dispatchBadToken();
            }

            // For any other 403 response or error continue with our normal behaviour
            if (response && response.statusCode === 403) this.errorCallback(err, response);

            // Any other error is going to be ignored
            
            if (callback) callback(error, <Response<T>>response);
        });
    }



    protected finishRequest(request: any, callback?: any, query?: any) {
        //@ts-ignore
        const apiEndpoint = (process.env.NEXT_PUBLIC_DISCOVERY_URI ? process.env.NEXT_PUBLIC_DISCOVERY_URI : 'https://cilegacy.innovationwithin.services') + "/" + uuid.generate();

        axios.post(apiEndpoint, request).then((value) => {
            const data = value.data.rb;

            //@ts-ignore
            let resp = { body: data, text: JSON.safeStringify(data), statusCode: 200 }

            // If their token has expired, we freeze the app and show them a dialog prompting them to
            // sign back in.
            if (response && response.statusCode === 403 && response.text === 'Illegitimate access code') {
                return dispatchBadToken();
            }

            //@ts-ignore
            if (callback) callback(undefined, resp);
            
        }, (error)=> {
            appLog(error);
        }).catch((error) => {
            appLog(error);
        });
    }

    request(type: string,
        route: string,
        query?: Object,
        body?: Object,
        callback?: CallbackHandler): any {
        //@ts-ignore
        const apiEndpoint = (process.env.NEXT_PUBLIC_DISCOVERY_URI ? process.env.NEXT_PUBLIC_DISCOVERY_URI : 'https://cilegacy.innovationwithin.services') + "/" + uuid.generate();

        //jtb9-can't remember why this is here... guess it'll be here forever *shrug*
        //superagent DELETE = del() ... dumb..
        type = (type === 'delete') ? 'del' : type;

        const at = localStorage.getItem('accessToken');

        let _query = query;

        if (_query && !isEmpty(_query)) {
            //@ts-ignore
            if (_query.accessToken === undefined) {
                //@ts-ignore
                _query['accessToken'] = at;
            }
            
        }
        else {
            _query = {
                accessToken: at
            }
        }

        if (type === 'post' && body && !isEmpty(body)) {
            if (body === undefined || body === null) { body = {} };

            //@ts-ignore
            if (body.accessToken === undefined) {
                //@ts-ignore
                body['accessToken'] = at;
            }
        }

        const vinxPayload = {
            url: this.getEndpoint(route),
            method: type.toUpperCase(),
            _body: body,
            action: 'get',
            query: _query,
            headers: {
                "Authorization": "Basic aXc6Z3N2bGFicw==",
            }
        }

        // paw('discovery', {userID: ''}, vinxPayload).then((results) => {
        //     // check if results are a passthrough url
        //     let isAUrl = false;

        //     try {
        //         //@ts-ignore
        //         const data = results.data;

        //         if (typeof(data) === "string") {
        //             //@ts-ignore
        //             if (data.indexOf("https://") >= 0) {
        //                 isAUrl = true;
        //             }
        //         }
        //     }
        //     catch(e) {
        //         console.error(e);
        //     }

        //     if (isAUrl) {
        //         results['text'] = results.data;

        //         // For now we're going to let the existing behaviour handle this 
        //         //@ts-ignore
        //         callback(undefined, results);
        //     }
        //     else {
        //         results['body'] = results.data;
                
        //         //@ts-ignore
        //         callback(undefined, results);
        //     }
        // }, (error) => {
        //     console.log(`paw error ${JSON.stringify(error)}`);

        //     callback(error, error);
        // });

        return;
    }
            // else {
            //     results['body'] = results.data;

    get<T>(route: string, query?: Object, callback?: CallbackHandler<T>): Request {
        return this.request('get', route, query, undefined, callback);
    }
        // }, (error) => {
        //     console.log(`paw error ${JSON.stringify(error)}`);

        //     callback(error, error);
        // });

    post<T>(route: string, query?: Object, body?: Object, callback?: CallbackHandler<T>) {
        return this.request('post', route, query, undefined, callback);
    }

    put<T>(route: string, query?: Object, callback?: CallbackHandler<T>) {
        return this.request('put', route, query, undefined, callback);
    }

    delete<T>(route: string, query?: Object, callback?: CallbackHandler<T>) {
        return this.request('delete', route, query, undefined, callback);
    }

    upload<T>(route: string, body: Dictionary<any>, files: Dictionary<Blob|File>, callback: CallbackHandler<T>) {
        let request: Request = superagent.post(this.getEndpoint(route));

        each(body, (val, key) => {
            if (val) request.field(key, val);
        });

        each(files, (file, key) => {
            if (file) request.attach(key, file, file instanceof File ? (<File>file).name : undefined);
        });

        this.setupRequest(request);

        this.finishRequest(request, callback);
    }

    putUpload<T>(route: string, body: Dictionary<any>, files: Dictionary<Blob>, callback: CallbackHandler<T>) {

        // each(body, (val, key) => {
        //     if (val) request.field(key, val);
        // });

        const fullRoute = `${this.routePrefix}${route}`;

        let request = {
            body, files, route: fullRoute, url: route, method: 'put-upload'
        }

        this.finishRequest(request, callback, undefined);
    }
}
