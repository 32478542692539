import {Model, CollectionArray, State} from '../base';
import {keyBy, get, each} from 'lodash';

import {dispatcher, dispatch} from '../../../lib/pork';
import {billingAPI, userAPI} from '../../api';
import {constructAction} from '../util';
import { orgApi } from '../../api/org';

export class BillingState  {
}

class Customer {
}

class Payment {
}

export class Payments {
}

class Transaction {
}

class Transactions {
    
}

let billingState = new BillingState();
export default billingState;
