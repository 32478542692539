import React, { useEffect, useState } from 'react';
import 'scss/index.scss';
import 'scss/vars-and-mixins.scss';
import 'app/components/admin/cohort-periods.scss';
import 'app/components/audio/audio.scss';
import 'app/components/canvas/canvas.scss';
import 'app/components/discovery/index.scss';
import 'app/components/discovery/editor/index.scss';
import 'app/components/discovery/finding-editor.scss';
import 'app/components/discovery/discovery-navigation.scss';
import 'app/containers/dashboard/style.scss';
import 'app/containers/app/app.scss';
import 'app/containers/wiki/beautifulDnDStyles.css';
import 'app/components/discovery/interview-list.scss';
import 'app/components/general/customSearch.scss'
import '@uppy/core/dist/style.css';
import '@uppy/webcam/dist/style.css';
import '@uppy/url/dist/style.css';
import '@uppy/dashboard/dist/style.css';
import '@uppy/audio/dist/style.css';
import '@uppy/informer/dist/style.css';
import 'react-h5-audio-player/lib/styles.css';
import '@uppy/file-input/dist/style.css';
import 'react-select-search/style.css';
import 'remirror/styles/all.css';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DiscoverySessionState, discoverySessionAtom } from '../app/stores/jotai/discovery-session';
import IwSplashScreen from "../app/components/app/IwSplashScreen";

import { useAtom } from 'jotai';
import LogRocket from 'logrocket';

LogRocket.init('wvyuji/discovery-platform-v3');

// we should initialize sentry app wide

// Sentry.init({
//   dsn: "https://804ab2fc5f4047d8b52703a30568d21e@o319583.ingest.sentry.io/1809170",
//   replaysSessionSampleRate: 0.1,
//   // If the entire session is not sampled, use the below sample rate to sample
//   // sessions when an error occurs.
//   replaysOnErrorSampleRate: 1.0,

//   integrations: [new Sentry.Replay()]
// });

function DApp({ Component, pageProps }) {
  const [discoverySession, setDiscoverySession] = useAtom(discoverySessionAtom);
  const [splashScreenShowCount, setSplashScreenShowCount] = useState<number>(1);

  useEffect(() => {
    // display our build version
    console.log(`running build ${process.env.NEXT_PUBLIC_LATEST_COMMIT_HASH} local time ${new Date().toUTCString()}}`);

    // Remove the server-side injected CSS.
    try {
      const jssStyles = document.querySelector('#jss-server-side');
      if (jssStyles) {
        //@ts-ignore
        jssStyles.parentElement.removeChild(jssStyles);
      }
    }
    catch (e) {
      
    }
  }, []);

  useEffect(() => {
      if (
          discoverySession.state === DiscoverySessionState.ready &&
          splashScreenShowCount <= 1
      ) {
          setSplashScreenShowCount((preCount) => preCount + 1);
      }
  }, [discoverySession.state]);

  // return <Sentry.ErrorBoundary
  //   showDialog={false}
  //   fallback={({ error, componentStack, resetError }) => (
  //     <div style={{
  //       width: '100%',
  //       height: '100%',
  //       backgroundColor: '#1f445a',
  //       display: 'flex',
  //       justifyContent: 'center',
  //       alignItems: 'center',
  //     }}>
  //       <div style={{
  //         width: '50%',
  //         backgroundColor: '#eeeeee',
  //         margin: 'auto',
  //         padding: '10px',
  //         textAlign: 'center',
  //         borderRadius: '3px',
  //       }}>
  //         <h2>Sorry, something went wrong</h2>
  //         <p style={{ marginBottom: '30px' }}>Our team has been notified of the issue and are looking into it. Sometimes small flukes can be resolved by simply <u>refreshing the page</u> and retrying the action.</p>
  //         <p style={{ marginBottom: '30px' }}>The button below will take you back to your last stable page, but if you run into a problem doing the same thing more than once, feel free to send us more details on your issue. This will help us diagnose and resolve your issue much faster!</p>
  //         <Accordion sx={{ marginBottom: '30px' }}>
  //           <AccordionSummary
  //             expandIcon={<ExpandMoreIcon />}
  //             aria-controls="error-details-form"
  //             id="error-details-form"
  //           >
  //             <Typography>Click here to send details about your recurring issue</Typography>
  //           </AccordionSummary>
  //           <AccordionDetails>
  //             Sentry form
  //           </AccordionDetails>
  //         </Accordion>
  //         {/* <div>{error.toString()}</div> */}
  //         {/* <div>{componentStack}</div> */}
  //         <button
  //           onClick={() => {
  //             {/* When resetError() is called it will remove the Fallback component */ }
  //             {/* and render the Sentry ErrorBoundary's children in their initial state */ }
  //             resetError();
  //           }}
  //         >
  //           Take me back to safety!
  //         </button>
  //       </div>
  //     </div>
  //   )}
  // >
  return (
      <LocalizationProvider dateAdapter={AdapterDayjs}>
          <IwSplashScreen visible={splashScreenShowCount <= 1} />
          {/* Wait for user data */}
          <Component
              sx={{ backgroundColor: "#1c445b" }}
              {...pageProps}
          />
      </LocalizationProvider>
  );
  {/* </Sentry.ErrorBoundary> */}
}

// Only uncomment this method if you have blocking data requirements for
// every single page in your application. This disables the ability to
// perform automatic static optimization, causing every page in your app to
// be server-side rendered.
//
// MyApp.getInitialProps = async (appContext) => {
//   // calls page's `getInitialProps` and fills `appProps.pageProps`
//   const appProps = await App.getInitialProps(appContext);
//
//   return { ...appProps }
// }

export default DApp
