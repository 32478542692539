/// <reference path='./index.d.ts' />

import {CallbackHandler} from '../base';
import {DiscoveryApi} from '../discovery-api';

class TranscriptApi extends DiscoveryApi {
    routePrefix = 'transcription';

    revert(interviewID: string, callback: CallbackHandler<api.GenericResponse>): void {
        this.request('post', '/revert', {
            interviewID
        }, null, callback);
    }
}

export let transcriptApi = new TranscriptApi();
