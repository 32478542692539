/// <reference path='./index.d.ts' />

import {Request} from 'superagent';

import {CallbackHandler} from '../base';
import {DiscoveryApi} from '../discovery-api';

class BillingAPI extends DiscoveryApi {
    routePrefix = 'billing';

    getCustomer(callback?: CallbackHandler<BillingAPI.CustomerResponse>) {
        return this.get('/customer', undefined, callback);
    }

    getPricing(callback?: CallbackHandler<BillingAPI.PricingResponse>) {
        return this.get('/prices', undefined, callback);
    }

    createCustomer(request: BillingAPI.ChangeCustomerRequest, callback?: CallbackHandler<api.GenericResponse>) {
        return this.post('/customer', request, undefined, callback);
    }

    updateCustomer(request: BillingAPI.ChangeCustomerRequest, callback?: CallbackHandler<api.GenericResponse>) {
        return this.put('/customer', request, callback);
    }

    getCredit(request: BillingAPI.CreditRequest, callback?: CallbackHandler<BillingAPI.CreditResponse>) {
        return this.get('/credit', request, callback);
    }

    purchaseCredit(request: BillingAPI.PurchaseRequest, callback?: CallbackHandler<api.GenericResponse>) {
        return this.post<api.GenericResponse>('/credit', request, undefined, callback);
    }

    giveFreeCredit(request: BillingAPI.FreeCreditRequest, callback?: CallbackHandler<api.GenericResponse>) {
        return this.post<api.GenericResponse>('/credit/free', request, undefined, callback);
    }

    createFreeInvoice(request: BillingAPI.UserInvoiceRequest, callback: CallbackHandler<api.GenericResponse>) {
        return this.post("/invoice", request, undefined, callback);
    }
    
    createUserInvoice(request: BillingAPI.UserInvoiceRequest, callback: CallbackHandler<api.GenericResponse>) {
        return this.post("/invoice/user", request, undefined, callback);
    }

    createLicenseRequest(request: BillingAPI.LicenseRequestRequest, callback?: CallbackHandler<api.GenericResponse>) {
        return this.post('/invoice/request', request, undefined, callback);
    }

    getFreeTokens(request: BillingAPI.FreeTokensRequest, callback: CallbackHandler<api.GenericResponse>) {
        return this.get("/invoice/freetokens", request, callback);
    }

    reserveToken(request: BillingAPI.ReserveTokenRequest, callback: CallbackHandler<api.GenericResponse>) {
        return this.put("/invoice/reservetoken", request, callback);
    }

    getProduct(request: BillingAPI.ProductRequest, callback: CallbackHandler<BillingAPI.Product>) {
        return this.get("/product", request, callback);
    }

    getAllProducts(orgID: string, callback: CallbackHandler<BillingAPI.Product[]>) {
        return this.get("/products", { orgID }, callback);
    }

    getAllProductTokens(orgID: string, callback: CallbackHandler<BillingAPI.ProductToken[]>) {
        return this.get("/invoice/alltokens", { orgID }, callback);
    }

    getTransactions(request: BillingAPI.TransactionsRequest, callback?: CallbackHandler<BillingAPI.TransactionsResponse>) {
        return this.get('/credit/transactions', request, callback);
    }

    getPayments(callback?: CallbackHandler<BillingAPI.PaymentsResponse>) {
        return this.get('/payments', undefined, callback);
    }
}

export let billingAPI = new BillingAPI();
if (process.env.NEXT_PUBLIC_DEVELOPMENT) ((typeof window !== "undefined") ? window : {})['billing'] = billingAPI;
