export function lodashContains(source: any[], item: any) {
    try {
        for (let i = 0; i < source.length; i++) {
            if (source[i] === item) {
                return true;
            }
        }
    }
    catch (e) {
        console.error(e);
    }


    return false;
}

export function lodashKeyBy(collection: any[], newKey: any ) {
    try {
        let newObj = {}
        for (let i = 0; i < collection.length; i++) {
            newObj[collection[i][newKey]] = collection[i]
        }
        return newObj
    }
    catch (e) {
        console.log(e)
    }
}


