import axios from "axios";
import { DiscoverySession } from "./discovery-session";
import { redirectToMaintenanceErrorPage } from "../util";
import { appLog } from "../../util";

const NETWORK_ADAPATER = "axios";
const DEFAULT_GENERIC_PROVIDER_METHOD = "post";

function genericRequestProviderMinimal(href, method, onProgress?: any) {
    if (NETWORK_ADAPATER === "axios") {
        return axiosRequestProviderMinimal(href, method, onProgress);
    }
}

function axiosRequestProviderMinimal(href, method, onProgress?: any) {
    return new Promise((resolve, reject) => {
        axios.request({
            url: href,
            method: method,
            withCredentials: false,
            onUploadProgress: (progressEvent) => {
                if (onProgress) {
                    //@ts-ignore
                    if (progressEvent.lengthComputable) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onProgress(percentCompleted);
                    }
                }
            },
            onDownloadProgress: (progressEvent) => {
                if (onProgress) {
                    //@ts-ignore
                    if (progressEvent.lengthComputable) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onProgress(percentCompleted);
                    }
                }
            }
        }).then((r) => {
            resolve(r.data);
        }).catch((error) => {
            if (error?.response?.status === 503) {
                redirectToMaintenanceErrorPage();
            }

            reject(error);
        })
    });
}

function genericRequestProvider(apiKey, body, href, options, method: any, session, onProgress?: any) {
    if (NETWORK_ADAPATER === "axios") {
        return axiosRequestProvider(apiKey, body, href, options, method, session, onProgress);
    }
}

function axiosRequestProvider(apiKey, body, href, options, method: any, session, onProgress?: any) {
    return new Promise((resolve, reject) => {
        axios.request({
            ...options,
            url: href,
            data: body,
            method: method,
            withCredentials: false,
            headers: {
                "Authorization": 'Bearer ' + apiKey
            },
            onUploadProgress: (progressEvent) => {
                if (onProgress) {
                    //@ts-ignore
                    if (progressEvent.lengthComputable) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onProgress(percentCompleted);
                    }
                }
            },
            onDownloadProgress: (progressEvent) => {
                if (onProgress) {
                    //@ts-ignore
                    if (progressEvent.lengthComputable) {
                        const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                        onProgress(percentCompleted);
                    }
                }
            }
        }).then((r) => {
            resolve(r.data);
        }).catch((error) => {
            if (error?.response?.status === 503) {
                redirectToMaintenanceErrorPage();
            }

            reject(error);
        })
    });
}

function paramsMiddleware(session, params) {
    return {
        ...params,
        sessionID: session.sessionID
    }
}

function bodyMiddleware(session, body) {
    return {
        ...body,
        sessionID: session.sessionID
    }
}

async function provider(session: DiscoverySession, path: string, body: any, options: any, method: any, onProgress?: any) {
    try {
        // generically upercase the method
        method = method.toUpperCase();

        // get our modular api key
        const apiKey = session.apiToken;

        // construct a full url (technically hard coded to HTTP)
        let baseURL = process.env.NEXT_PUBLIC_LINX_URL;
        let href = `${baseURL}${path}`;

        // run through our middleware and inject anything in the body that should always be there
        body = bodyMiddleware(session, body);

        // check for params to run through the middleware
        if (options && options.params) {
            options.params = paramsMiddleware(session, options.params);
        }

        // call our generic request provider
        let initialResponse = await genericRequestProvider(apiKey, body, href, options, method, session, onProgress);

        // check for requests to another URL
        //@ts-ignore
        if (initialResponse && initialResponse.signedURL !== undefined) {
            //@ts-ignore
            appLog(`following request to ${initialResponse.signedURL}`)

            try {
                // call our minimal generic request provider, for non LINX based data sources
                //@ts-ignore
                initialResponse = await genericRequestProviderMinimal(initialResponse.signedURL, 'get', onProgress);
            }
            catch(e) {
                console.error(e);
            }
        }

        // return the response
        return initialResponse;
    }
    catch (e) {
        console.error(e);
    }
}

export async function api(path: string, body: any, method: string) {

}

function pathMiddleware(session, path) {
    if (path.indexOf("/") !== 0) {
        return `/${path}`
    }

    return path;
}

// These are 2 entry points to make API calls, pass the userID to get drop-in authentication
// the other version is just a callback version
export async function protectedApiCall(session: DiscoverySession, path: string, body: any, options: any, method: any, onProgress?: any) {
    if (method === undefined) method = 'get';

    path = pathMiddleware(session, path);

    if (method === undefined) method = DEFAULT_GENERIC_PROVIDER_METHOD;
    return await provider(session, path, body, options, method, onProgress);
}

export function protectedApiCallCallback(session: DiscoverySession, path: string, body: any, options: any, onDone: any, method: any, onProgress?: any) {
    if (method === undefined) method = 'get';

    path = pathMiddleware(session, path);

    if (method === undefined) method = DEFAULT_GENERIC_PROVIDER_METHOD;
    provider(session, path, body, options, method, onProgress).then((r) => {
        onDone(r);
    })
}

// function keycloakApiKeyProvider(userID) {
//     return getStoredApiKey(userID);
// }

// // same modular pattern for the requests providers.  allows us to simply swap out the authentication provider
// async function apiKeyProvider(userID) {
//     return keycloakApiKeyProvider(userID);
// }

// function getStoredApiKey(userID: string) {
//     if (typeof window !== "undefined") {
//         return window.sessionStorage.getItem("iw-last-apiKey-" + userID);
//     }
//     return null;
// }

// export function storeApiKey(userID: string, latestApiKey: string) {
//     if (typeof window !== "undefined") {
//         window.sessionStorage.setItem("iw-last-apiKey-" + userID, latestApiKey);
//     }
// }
