import { ActionSignature, dispatch } from '../../../lib/pork';
import { omit, each } from 'lodash';

import request from 'superagent';

 

import { constructAction } from '../util';

import {AppStore} from './index';
import {loadInterview} from '../discovery/actions';
import { DiscoveryApi } from '../../api/discovery-api';
import { userHasRole, userHasSubRole } from '../../util';

const appNamespace = AppStore.namespace;

export function orientCanvas(alternativeCanvasType: string) {
    dispatch(constructAction(`${appNamespace}.orientCanvas`, alternativeCanvasType));
}

export function toggleCanvasLinkBadgeView() {
    dispatch(constructAction(`${appNamespace}.toggleCanvasLinkBadgeView`, {}));
}

export function toggleCanvasAllCommentsView() {
    dispatch(constructAction(`${appNamespace}.toggleCanvasAllCommentsView`, {}));
}

export function toggleCanvasLinkedByColor() {
    dispatch(constructAction(`${appNamespace}.toggleCanvasLinkedByColor`, {}));
}

export function setUnreadNotificationCount(count: number) {
    dispatch(constructAction(`${appNamespace}.setUnreadNotificationCount`, {count}));
}

export function toggleCanvasFindingLinkBadgeView() {
    dispatch(constructAction(`${appNamespace}.toggleCanvasFindingLinkBadgeView`, {}));
}

export function toggleInterviewList() {
    dispatch(constructAction(`${appNamespace}.toggleInterviewList`, {}));
}

export function selectInterviewAction(interviewID: string) {
    return constructAction(`${appNamespace}.selectInterview`, interviewID);
}

export function selectPresentationWeek(week: number) {
    dispatch(constructAction(`${appNamespace}.selectPresentationWeek`, {week}));
}

export function selectPresentationGroup(groupID: string) {
    dispatch(constructAction(`${appNamespace}.selectPresentationGroup`, {groupID}));
}

export function setNubMap(canvasData: any) {
    dispatch(constructAction(`${appNamespace}.setNubMap`, {canvasData}));
}

export function selectInterview(interviewID: string | undefined) {
    // when a user selects an interview, the URL should no longer reflect
    // the old one they were on
    // pageChange('/discovery');

    dispatch(selectInterviewAction(interviewID));

    // this will be called without interviewID to unselect an interview
    if (!!interviewID) loadInterview(interviewID);
}

export function toggleOrgTree(isAdminView: boolean = false) {
    dispatch(constructAction(`${appNamespace}.toggleOrgTree`, {isAdminView}));
}

export function setOrgTree(target: boolean) {
    if (target) {
        dispatch(constructAction(`${appNamespace}.openOrgTree`, false));
    }
    else {
        dispatch(constructAction(`${appNamespace}.closeOrgTree`, false));
    }
}

export function setPresentationFlag(target: boolean) {
    const action = constructAction(`${appNamespace}.setPresentationRefreshFlag`, {value: target})
    dispatch(action);
}

export function setPathRequest(path: string) {
    const action = constructAction(`${appNamespace}.setPathRequest`, {value: path});
    dispatch(action);
}

// Toggle the beacon object into our out of view
export function beaconToggleView() {
   try {
    //@ts-ignore
    window.Beacon("toggle");
   }
   catch(e) {
    console.error(e);
   }
}

export function _getBeaconData() {
    let newSuggestionDefinition = {
        feature: undefined,
        roles: undefined,
        user_name: undefined,
        user_email: undefined,
        user_obj: undefined,
    };

    if (((typeof window !== "undefined") ? window : {})["beacon_suggestion_definition"]) {
        newSuggestionDefinition = ((typeof window !== "undefined") ? window : {})["beacon_suggestion_definition"];
    }

    return newSuggestionDefinition;
}

export function _setBeaconData(data) {
    ((typeof window !== "undefined") ? window : {})["beacon_suggestion_definition"] = data;
}

export function beaconReportUserData (user) {
    let newSuggestionDefinition = _getBeaconData();

    newSuggestionDefinition["user_name"] = user.name;
    newSuggestionDefinition["user_email"] = user.email;
    newSuggestionDefinition["user_obj"] = user;

    _setBeaconData(newSuggestionDefinition);
}

export function beaconReportCurrentFeature(feature) {
    let newSuggestionDefinition = _getBeaconData();

    newSuggestionDefinition["feature"] = feature;

    _setBeaconData(newSuggestionDefinition);
}

export function beaconReportCurrentRoles(roles: string[]) {
    let newSuggestionDefinition = _getBeaconData();
    
    let roleSet = "";

    each (roles, role => {
        roleSet += role + ",";
    });

    newSuggestionDefinition["roles"] = roleSet;

    _setBeaconData(newSuggestionDefinition);
}

export function loadBeaconSuggestions() {

    // Load beacon suggestions from our global artifact
    const beaconArtifactURL = DiscoveryApi.beaconHref;

    // Download it
    // TODO: Untill suggestions work, let's not download the payload
    // and save some bandwith
    //  request
    //      .get(beaconArtifactURL)
    //      .then(res => {
    //          // Flag that beacon is now loaded
    //          // dispatch(constructAction(`${appNamespace}.setBeaconAsLoaded`, res.body.payload));
    //          window["beacon_suggestion_payload"] = res.body.payload;
    //  });
}

export function toggleChat() {
    dispatch({
        error: false, type: `${appNamespace}.toggleChat`
    });
}

export function clearLogoutFlag() {
    const actionType = `${appNamespace}.clearLogoutFlag`;

    dispatch(constructAction(actionType, {}));
}

export function flagLogOut() {
    const actionType = `${appNamespace}.flagForLogout`;

    dispatch(constructAction(actionType, {}));
}

// Manage our toast queue here
export function addToastToQueue(toast: any) {
    dispatch(constructAction(`${appNamespace}.addToToastQueue`, toast));
}

export function removeToastFromQueue(toast: any) {
    dispatch(constructAction(`${appNamespace}.removeToastFromQueue`, toast));
}

export function setFlagUserEditLastMessage(payload: any) {
    dispatch(constructAction(`${appNamespace}.setFlagUserEditLastMessage`, payload));
}

export function toggleNotification() {
    dispatch({
        error: false, type: `${appNamespace}.toggleNotification`
    });
}

export function setNotificationOff() {
    dispatch({
        error: false, type: `${appNamespace}.setNotificationOff`
    });
}

export function setNotificationSettingsVisible(target: boolean) {
    dispatch(constructAction(`${appNamespace}.setNotificationSettingsIsOpen`, target));
}

export function setIsFetchingNotes(target: boolean) {
    dispatch(constructAction(`${appNamespace}.setIsFetchingNotes`, target));
}

export function setShowConflictPrompt(target: boolean, username: string) {
    dispatch(constructAction(`${appNamespace}.setShowConflictPrompt`, [target, username]));
}

export function setShowSavingArtifactText(target: boolean) {
    dispatch(constructAction(`${appNamespace}.setIsSavingArtifactText`, target));
}

export function setIsFetchingTranscript(target: boolean) {
    dispatch(constructAction(`${appNamespace}.setIsFetchingTranscript`, target));
}

export function setIsFetching(target: boolean) {
    dispatch(constructAction(`${appNamespace}.setIsFetching`, target));
}

export function setCurrentTab(target: Number) {
    dispatch(constructAction(`${appNamespace}.setCurrentTab`, {target}));
}

//Org Tree / chart column / period select
// TODO: this name is too generic to be in something as global as app/actions
export function setSelected(key: string, selected: number) {
    dispatch({
        error: false,
        type: `${appNamespace}.setSelected`,
        payload: { key: key, data: selected }
    })
}
