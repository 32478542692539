/// <reference path='./index.d.ts' />

import {default as superagent, Request, Response} from 'superagent';
import {assign, get} from 'lodash';

import {CallbackHandler} from '../base';
import {DiscoveryApi} from '../discovery-api';
import axios from 'axios';

/**
 * API for all Interviews and their data, includes artifacts (audio, transcription).
 */
class InterviewApi extends DiscoveryApi {
    routePrefix = 'discovery';

    findingRoute = '/interview/finding';
    transcribeRoute = '/interview/transcription/v2';
    noteRoute = '/interview/notes';

    details(groupID: string, callback: CallbackHandler<api.DiscoveryDetailsResponse>) {
        this.get('', <api.DiscoveryDetailsRequest>{ groupID }, callback);
    }

    getInterview(interviewID: string, callback: CallbackHandler<api.InterviewResponse>) {
        this.get('/interview', <api.InterviewRequest>{ interviewID }, callback);
    }

    createInterview(groupID: string, details: api.Interview, callback: CallbackHandler<api.Interview>) {
        let query = <api.InterviewPostRequest>assign({ groupID }, details);

        this.post('/interview', query, undefined, callback);
    }

    updateInterview(interviewID: string, details: api.Interview, callback: CallbackHandler<api.Interview>) {
        let query = {
            interviewID, ...details
        };

        this.put('/interview', query, callback);
    }

    deleteInterview(interviewID: string, callback: CallbackHandler<string>) {
        this.delete('/interview', <api.InterviewRequest>{ interviewID }, callback);
    }

    uploadAudioName(interviewID: string, audioLocation: string, callback: CallbackHandler<any>) {
        let audioPost: api.AudioRequest = {
            interviewID, audioLocation
        };

        this.post('/interview/audio/v2', audioPost, undefined, callback);
    }

    // This returns a signed url to upload an artifact directly into our 
    // artifact bucket.  It expires after 2 hours
    getSignedURL(filename: string, contentType: string, callback: CallbackHandler<string>) {
        this.get<string>("/interview/audio/signedurl", { filename, contentType }, callback);
    }

    getSignedURLV2(filename: string, contentType: string, teamID: string, callback: CallbackHandler<string>) {
        this.get<string>("/interview/audio/signedurl/v2", { filename, contentType, teamID }, callback);
    }


    deleteArtifact(artifactID: string, callback?: CallbackHandler<api.GenericResponse>) {
        return this.delete('/interview/artifact', { artifactID }, callback);
    }

    exportInterviews(groupID: string, callback: CallbackHandler<string>) {
        return this.get('/interviews/export', { groupID }, callback);
    }

    //Transcription
    transcribe(interviewID: string, method: string, speakerCount: string, callback: CallbackHandler<api.TranscriptArtifact>) {
        this.post(this.transcribeRoute, <api.TranscriptPost>{
            interviewID,
            speakerCount,
            method
        }, undefined, callback);
    }

    createTranscription(interviewID: string, callback: CallbackHandler<string>) {
        return this.post('/interview/artifact/transcript', { interviewID }, undefined, callback);
    }

    updateTranscription(interviewID: string, artifactID: string, transcription: api.TranscriptData, callback: CallbackHandler<api.GenericResponse>) {
        this.putUpload(this.transcribeRoute + '/update', {
            interviewID, artifactID, transcription: JSON.stringify(transcription)
        }, undefined, callback);
    }

    updateTranscriptionWorker(user: User, interviewID: string, artifactID: string, transcription: api.TranscriptData, callback: CallbackHandler<api.GenericResponse>) {
        // navigator.serviceWorker.controller.postMessage({
        //     cmd: 'save',
        //     interviewID,
        //     transcription: JSON.stringify(transcription),
        //     artifactID,
        //     accessToken: user.accessToken,
        //     endpoint: this.getEndpoint('/interview/transcription/update/v2'),
        //     auth: this.authToken()
        // });
    }

    getTranscriptionArtifacts(interviewID: string, callback: CallbackHandler<api.TranscriptResponse>) {
        this.get(this.transcribeRoute, <api.TranscriptRequest>{interviewID}, callback);
    }

    getTranscriptionArtifactData(dataURL: string, callback: CallbackHandler<api.TranscriptData>) {
        this.get<api.TranscriptData>(dataURL, {auth: true}, (error, response) => {
            if (error) return callback(error, response);

            if (response.type === 'application/json') {
                response.body = JSON.parse(response.text);
            }

            return callback(null, response);
        });
    }

    // Notes
    getNotes(interviewID: string, callback: CallbackHandler<api.NotesResponse>) {
        this.get(this.noteRoute, {interviewID}, callback);
    }

    createNotes(interviewID: string, notes: string, callback: CallbackHandler<api.NotesResponse>) {
        this.post('/interview/notes/v3', {interviewID, notes}, null, callback);
    }

    updateNotes(interviewID: string, notes: string, previousHash: string, callback: CallbackHandler<api.NotesResponse>) {
        this.post('/interview/notes/v3', {interviewID, notes, previousHash}, null, callback);
    }

    updateNotesWorker(interviewID: string, notes: string, user:User, previousHash: string, callback: CallbackHandler<api.NotesResponse>) {
        // navigator.serviceWorker.controller.postMessage({
        //     cmd: 'save',
        //     interviewID,
        //     notes,
        //     accessToken: user.accessToken,
        //     endpoint: this.getEndpoint('/interview/notes/v2'),
        //     auth: this.authToken()
        // });
    }

    getNotesArtifactData(dataURL: string, callback: CallbackHandler<string>) {
        // this.get<string>(dataURL, {auth: true}, (error, response) => {
        //     if (error) return callback(error, response);
        //     return callback(null, response);
        // });
 
        if (dataURL === undefined) {
            //console.log('bad dataURL');

            return callback(null, {text: "{}"});
        }
        else {
        // axios.get(dataURL).then((value) => {
        //         let response = {
        //             text: JSON.stringify(value.data)
        //         }

        //         return callback(null, response);
        //     });
        }
    }

    getTemplates(groupID: string, callback: CallbackHandler<any>) {
        this.get('/template', {groupID}, callback);
    }

    putTemplate(templateID: string, name: string, data: string, callback: CallbackHandler<any>) {
        this.post('/template/data/v2', {templateID, name, data}, undefined, callback);
    }

    deleteTemplate(templateID: string, callback: CallbackHandler<any>) {
        this.delete('/template', {templateID}, callback);
    }

    postTemplate(name: string, groupID: string, callback: CallbackHandler<any>) {
        this.post('/template', {name, groupID}, undefined, callback);
    }

    //Finding
    createFinding(findingPost: api.FindingPostRequest, callback: CallbackHandler<api.FindingResponse>) {
        //this.post(this.findingRoute, findingPost, undefined, callback);

        axios.post(`${process.env.NEXT_PUBLIC_LINX_URL}/refactor/insight`, {
            ...findingPost,
            accessToken: DiscoveryApi.accessToken
        }).then((value) => {
            let response = {
                body: value.data
            }

            return callback(null, response);
        });
    }

    getFinding(id: string, callback: CallbackHandler<api.FindingResponse>) {
        this.get(this.findingRoute, <api.FindingRequest>{ findingID: id }, callback);
    }

    getFindings(groupID: string, filter: DiscoveryAPI.FindingsFilter = undefined, callback: CallbackHandler<DiscoveryAPI.FindingsResponse>) {
        this.get('/findings', {
            groupID, filter: filter ? JSON.stringify(filter) : null
        }, callback);
    }

    getFindingsByEntry(groupID: string, entryID: string, callback: CallbackHandler<DiscoveryAPI.FindingsResponse>) {
        this.get('/findings/hypothesis', { groupID, entryID}, callback);
    }

    updateFinding(findingID: string, details: api.Finding, callback: CallbackHandler<api.FindingResponse>) {
        let putDetails: api.FindingPutRequest = assign({findingID}, details);

        this.put(this.findingRoute, putDetails, callback);
    }

    deleteFinding(findingID: string, callback: CallbackHandler<string>) {
        this.delete(this.findingRoute, <api.FindingRequest>{ findingID }, callback);
    }
    //stats
    stats(orgID: string, groupID: string, startDate: string, endDate: string, callback: CallbackHandler<api.StatsResponse>) {
        this.get('/stats', { orgID, groupID, startDate, endDate}, callback);
    }
}

export let interviewApi = new InterviewApi();
if (process.env.NEXT_PUBLIC_DEVELOPMENT) ((typeof window !== "undefined") ? window : {})['interviewAPI'] = interviewApi;
