export const entryDragType = 'CANVAS_ENTRY';
export const emptyEntryDialogTexts = {
    title: "Hypothesis title can't be empty",
    description:
        "Just to make things smoother for you, we currently require a title when you create or edit a hypothesis. This little change helps us give you the best experience possible. If you happen to leave the title blank, don't worry - we'll just keep the previous title you had.",
};
export const CUSTOMER_SEGMENTS_CATEGORY_NAME = "Customer Segments";

export const CANVAS_CHIP_VARIANTS = {
    DEFAULT: "default",
    FILLED: "filled", 
}

export const ENTRY_TOOLBAR_POPOVER_OFFSET = 10;

export const ENTRY_TOOLBAR_POPOVER_DIMENSIONS = {
    COMMENT_POPOVER: {
        WIDTH: 300,
    },
    INSIGHT_POPOVER: {
        WIDTH: 360,
    },
    LINK_POPOVER: {
        WIDTH: 300,
    },
};

export const HYPOTHESIS_STATES = {
    VALIDATED: "Validated",
    INVALIDATED: "Invalidated",
    DELETED: "Deleted",
    HYPOTHESIS: "Hypothesis",
}

export const DELETE_INSIGHT_MODAL_TEXTS = {
	TITLE: "Delete Insight?",
	DESCRIPTION:
		"Deleting this insight will also unlink this from any hypotheses. Would you like to proceed?",
};
