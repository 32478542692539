import { ActionSignature, ActionHandlers, ActionCreator, ActionCreators } from './actions';
import { PubSub } from '../../pubsub';

import{ mapValues, defaults, defaultsDeep } from 'lodash';

export abstract class Store<Structure> {
    constructor(initialState?: Structure) {
        if (initialState) this.initialState = initialState;
    }

    // Abstract, to be implemented by subclass
    static namespace: string;
    initialState: Structure;
    actionHandlers: ActionHandlers = {};

    /**
     * commonly used to cache the store somewhere persistent, this is where a store can prepare data for storage.
     * @return {Object}          intended to return clean JSON (no class instances)
     */
    serialize(state: Structure): Object { return {}; }

    /** commonly used to process cached data before using it (ex: convert raw data to class instances). */
    deserialize(state: any): Structure { return <Structure>defaultsDeep(state, this.initialState); }

    errors = new PubSub<ActionSignature<any>>();

    // Take a state object and an action, return a new state object modified based on action
    reducer(state: Structure = this.initialState, action?: ActionSignature<any>): Structure {

        if (action.error) this.errors.publish(action);

        // remove the "<store title>." bit from the action type leaving just the handler name
        const actionHandlerName = action.type.replace(`${(<typeof Store>this.constructor).namespace}.`, '')

        const actionHandler = this.actionHandlers[actionHandlerName];

        if (actionHandler) {
            const actionResult = actionHandler.call(this, state, action);

            // if the child store has implemented an action handler for
            // this action type, this result will be the amalgamation
            // of its old state and this incoming action.
            return actionResult;
        }

        // if the child store has not implemented an action handler for this
        // action type, we'll return its state without modification.
        return state;
    }
}
